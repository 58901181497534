.bubble {
  display: flex;
  align-items: flex-start;

  img.bubble-image {
    width: 32px;
    height: 32px;
    margin-right: 12px;

    @include for-tablet {
      width: 52px;
      height: 52px;
      margin-right: 8px;
    }

    @include for-small-desktop {
      width: 66px;
      height: 66px;
      margin-right: 16px;
    }
  }

  .bubble-content {
    width: auto;
    max-width: 283px;
    padding: 12px 16px;
    font-size: 14px;
    color: #000000;
    border-radius: 3px 20px 20px 20px;

    @include for-tablet {
      padding: 12px 16px 12px 12px;
      max-width: 240px;
    }

    @include for-small-desktop {
      max-width: 320px;
    }
  }

  &.bubble--info .bubble-content {
    background: $baby-blue;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
  }

  &.bubble--warning .bubble-content {
    color: $white;
    font-weight: 700;
    background-color: $yellow-active;
    box-shadow: 0px 2px 10px rgba($yellow-active, 0.5);
  }

  &.bubble--danger .bubble-content {
    color: $white;
    font-weight: 700;
    background-color: $pink;
    box-shadow: 0px 2px 10px rgba($pink, 0.5);
  }
}
