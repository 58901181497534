.container {
  position: relative;
}

.bubble-container {
  min-height: 64px;
  padding: 16px 0 16px;

  @include for-tablet {
    display: flex;
    justify-content: center;
    padding: 16px 0 60px;
  }

  @include for-small-desktop {
    padding: 28px 0 78px;
  }
}

.services {
  display: flex;
  flex-direction: column;

  .services-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }

  .services-image {
    width: 120px;
    height: 60px;
    margin: 0 auto;

    &--vet {
      margin-right: 42px;
    }

    &--insurance {
      margin-left: 32px;
    }
  }

  @include for-tablet {
    flex-direction: row;
    justify-content: center;

    .services-item {
      flex: 1;
      max-width: 300px;
      margin: 0 10px;
    }

    .services-image,
    .services-image--vet,
    .services-image--insurance {
      height: 62px;
      margin: 0 auto;
    }
  }

  @include for-small-desktop {
    .services-item {
      max-width: 384px;
      margin: 0 12px;
    }
  }
}

.progress-contaier {
  position: relative;

  .progress {
    position: absolute;
    top: 8px;
    right: 0;
    left: 0;

    @include for-tablet {
      top: 28px;
    }
  }
}

.step {
  position: relative;
  margin-top: 20px;

  .step-title,
  .step-info {
    margin: 0 auto 54px;

    @include for-tablet {
      top: 48px;
      max-width: 588px;
    }
  }

  .step-images {
    display: flex;
    margin-bottom: 26px;
    color: $ionic-blue;

    @include for-tablet {
      max-width: 588px;
      justify-content: center;
      margin: 0 auto 26px;
    }
  }

  .step-image {
    margin: 0 auto;
  }

  .step-info {
    font-weight: 400;
  }

  &--checked {
    .step-images {
      color: $block;
    }

    .step-image--checked {
      color: $ionic-blue;
    }
  }

  .base-btn-group {
    display: flex;
    align-items: stretch;
    justify-content: center;

    @include for-tablet {
      .base-btn {
        max-width: 282px;
      }
    }
  }

  &--choose-pet .base-btn-group {
    flex-wrap: nowrap;
  }

  &--big-answer .base-btn-group {
    @include for-tablet {
      .base-btn {
        max-width: 384px;
      }
    }
  }

  .base-select {
    margin: 0 auto;
    z-index: 1;

    @include for-tablet {
      max-width: 370px;
    }
  }
}
