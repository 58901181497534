.app-bar {
  height: 58px;
  box-shadow: $card-shadow;
  background-color: $white;

  &--flat {
    box-shadow: none;
  }

  &--menu-opened {
    position: relative;

    .app-bar-menu-icon {
      color: $yellow-hover;
    }
  }
}

.app-bar-content {
  display: flex;
  align-items: center;
  margin: 0 auto;
  max-width: 1280px;
  height: 100%;

  @include screen-laptop {
    max-width: 994px;
  }

  @include screen-tablet {
    max-width: 738px;
  }

  @include screen-mobile {
    padding: 0 10px;
  }
}

.app-bar-left,
.app-bar-right {
  flex: 1;
  display: flex;
  align-items: center;
}

.app-bar-right {
  justify-content: flex-end;
}

.app-bar-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.app-bar-menu-icon {
  display: none;
  width: 32px;
  height: 32px;
  padding: 0;
  margin-right: 10px;
  color: $grey;
  border: none;
  background-color: transparent;

  @include screen-tablet {
    display: flex;
  }
}

.app-bar-logo {
  @include screen-mobile {
    display: none;
  }

  &--mobile {
    display: none;
    width: 142px;
    height: 44px;

    @include screen-mobile {
      display: flex;
    }
  }
}

.app-bar-langs {
  width: 12px;
  height: 12px;
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0 30px;
  color: $grey;
  border: none;
  background-color: transparent;

  @include screen-tablet {
    display: none;
  }
}

.app-bar-menu-item {
  color: $grey-active;
  text-decoration: none;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  cursor: pointer;

  svg {
    margin-right: 10px;
  }

  &--active {
    color: $blue;
  }

  &:hover {
    color: $grey-hover;
  }

  &:active {
    color: $blue;
  }
}

.app-bar-menu-desktop {
  display: flex;
  align-items: center;

  @include screen-tablet {
    display: none;
  }

  .app-bar-menu-item {
    display: flex;
    justify-content: center;
    margin-left: 42px;

    @include screen-tablet {
      display: none;
    }
  }
}

.app-bar-mobile-menu {
  position: absolute;
  top: 58px;
  width: 100%;
  background-color: $white;
  filter: drop-shadow($card-shadow);
  z-index: 1;

  .app-bar-menu {
    display: none;

    @include screen-tablet {
      display: flex;
      flex-direction: column;
      margin: 0 auto;
      height: 100%;
    }

    .app-bar-menu-item {
      &.active,
      &:hover,
      &:active {
        background-color: $light-babyblue;
      }
    }

    .app-bar-menu-item span {
      position: relative;
      display: flex;
      margin: 0 auto;
      padding: 12px 30px;
      max-width: 738px;

      &::after {
        content: '';
        position: absolute;
        right: 0;
        bottom: 0;
        width: calc(100% - 30px);
        height: 1px;
        border-bottom: 1px solid $block;
      }
    }
  }
}
