.base-form-floating {
  .base-input {
    height: 72px;
    padding: 20px 18px;
    border-radius: 10px;
    border: 2px solid $block;

    &.persistent-placeholder::placeholder {
      color: $grey-active;
    }

    &:hover {
      border-color: $blue-active;
    }

    &:focus {
      border-color: $blue-active;
      box-shadow: none;
    }

    &[readonly] {
      background-color: $white;
    }

    &.is-invalid {
      border-color: $pink;
      background-image: none;

      &:hover {
        border-color: $pink-hover;
      }

      &:focus {
        border-color: $pink-active;
      }
    }
  }

  .invalid-feedback {
    color: $pink;
    font-family: $font;
  }

  & > label {
    padding: 20px 18px;
    font-size: 18px;
    line-height: 30px;
    font-family: $font;
    color: $grey-active;
    white-space: nowrap;
  }

  & > .base-input:focus ~ label,
  & > .base-input:not(:placeholder-shown) ~ label,
  & > .form-select ~ label,
  & > .base-input.persistent-placeholder ~ label {
    height: auto;
    padding: 0 5px;
    color: rgba($grey-active, 0.69);
    font-family: $font;
    font-size: 12px;
    line-height: 30px;
    background-color: $white;
    transform: translateY(-15px) translateX(17px);
    opacity: 1;
  }
}

.base-form-floating > .base-input:focus,
.base-form-floating > .base-input:not(:placeholder-shown) {
  padding: 20px 18px;
  border-color: $baby-blue;
}

.base-form-floating > .base-input.is-invalid {
  border-color: $pink;
}
