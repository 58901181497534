.form-video-call {
  display: flex;
  flex-direction: column;
  margin: 140px auto 0;
  max-width: 588px;

  .form-video-call-title {
    margin: 0 auto 54px;

    @include for-tablet {
      top: 48px;
    }
  }

  .form-field {
    margin-bottom: 24px;
  }

  .form-submit-button {
    width: 165px;
    margin-left: auto;
  }
}
