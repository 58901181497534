// 1024
@mixin for-mobile {
  @include media-breakpoint-up(sm) {
    @content;
  }
}

@mixin for-small-tablet {
  @include media-breakpoint-up(md) {
    @content;
  }
}

// 1024
@mixin for-tablet {
  @include media-breakpoint-up(lg) {
    @content;
  }
}

// 1280
@mixin for-small-desktop {
  @include media-breakpoint-up(xl) {
    @content;
  }
}

// 1280
@mixin for-desktop {
  @include media-breakpoint-up(xxl) {
    @content;
  }
}

// screen sizes for design 2022
$mobile: 767px;
$tablet: 1024px;
$laptop: 1366px;

@mixin screen-laptop {
  @media only screen and (max-width: $laptop) {
    @content;
  }
}

@mixin screen-tablet {
  @media only screen and (max-width: $tablet) {
    @content;
  }
}

@mixin screen-mobile {
  @media only screen and (max-width: $mobile) {
    @content;
  }
}
