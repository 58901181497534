.base-select {
  position: relative;

  .dropdown-toggle::after {
    display: none;
  }

  .base-input {
    padding-right: 44px;

    &:read-only {
      cursor: pointer;
    }
  }

  & > .base-input:focus,
  & > .base-input:not(:placeholder-shown) {
    border-color: $block;
  }

  .base-select-arrow {
    position: absolute;
    top: 25px;
    right: 20px;
    color: $block;
    transition: all 0.2s;
    pointer-events: none;
  }

  &.show {
    .base-select-arrow {
      transform: rotate(-180deg);
    }
  }

  &--selected {
    .base-select-arrow {
      color: $baby-blue;
    }
  }

  &:hover,
  &.show {
    .base-input {
      border-color: $blue-active;
    }

    & .base-select-arrow {
      color: $blue-active;
    }
  }

  .base-select-menu {
    width: 100%;
    max-height: calc(45px * 6);
    padding: 0;
    border: 0;
    border-radius: 8px;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.1);
    overflow: auto;

    &::-webkit-scrollbar {
      width: 7px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px $grey-clock;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $block;
      border-radius: 8px;
      border-radius: 8px;
    }

    a:not(:last-child) {
      border-bottom: 1px solid $block;
    }
  }

  .base-select-option {
    padding: 12px 16px;
    font-family: $font;
    font-size: 14px;
    line-height: 20px;
    color: $grey-active;

    &:hover,
    &:focus {
      background-color: $background-grey;
    }

    &:active {
      background-color: $block;
    }
  }
}
