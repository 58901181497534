$white: #ffffff;

$grey: #7b899a;
$grey-hover: #94a2b3;
$grey-active: #323341;
$grey-clock: #e4e9eb;

$pink: #ff6b7f;
$pink-hover: #ff566d;
$pink-active: #ff4861;

$blue: #41b1f1;
$blue-hover: #1ca6f5;
$blue-active: #189ae4;

$green: #19ce87;
$green-hover: #09da88;
$green-active: #4ce2a7;

$yellow: #fdc731;
$yellow-hover: #ffbe0a;
$yellow-active: #f7b500;

$block: #d7dde1;
$baby-blue: #b1e9ff;
$background-grey: #fafafa;
$dark-grey: #708db1;
$ionic-blue: #134c5f;
$light-babyblue: #f1f9ff;
$light-baby-blue: #e8f9ff; // for design 2022
