.base-spinner {
  &.primary {
    color: $blue;
  }

  &.secondary {
    color: $block;
  }

  &.success {
    color: $green;
  }

  &.danger {
    color: $pink;
  }

  &.warning {
    color: $yellow;
  }

  &.lg {
    width: 46px;
    height: 46px;
  }
}
