.base-btn-group {
  margin: 0 -6px;
  display: flex;
  flex-wrap: wrap;
  flex: 1;

  @include for-tablet {
    margin: 0 -12px;
  }

  .base-btn.btn {
    margin: 0 6px 16px;
    width: 100%;

    @include for-tablet {
      margin: 0 12px 16px;
      padding: 20px;
    }
  }

  & > .base-btn:not(:last-child):not(.dropdown-toggle),
  & > .btn-group:not(:last-child) > .base-btn,
  .base-btn.btn {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  .btn-check:checked + .base-btn,
  .btn-check:active + .base-btn {
    background-color: $pink;
    box-shadow: 0px 8px 35px rgba($pink, 0.5);
  }
}

.base-btn-group.btn-group-vertical {
  margin: 0;

  .base-btn.btn {
    margin: 0 0 16px 0;
  }
}
