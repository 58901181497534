.next-available-time-slot {
  position: relative;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 20px 17px 20px 30px;
  background-color: $baby-blue;
  border-radius: 10px;

  @include screen-mobile {
    padding: 20px;
    border-radius: 0;
  }
}

.details {
  width: 100%;

  p {
    display: flex;
    align-items: center;
    min-height: 18px;
    margin-top: 2px;
    margin-bottom: 2px;
    line-height: 1;
  }
}

.detailsRow {
  display: flex;
}

.vetAvatar {
  width: 54px;
  height: 54px;
  flex-shrink: 0;
  margin-right: 20px;

  @include screen-mobile {
    margin-right: 10px;
  }
}

.availableTimeCaption {
  color: $blue;
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
}

.datetime {
  min-width: 120px;
  margin-right: auto;
  color: $ionic-blue;
  font-size: 18px;
  font-weight: 700;
}

.price {
  min-width: 50px;
  margin-left: 4px;
  color: $ionic-blue;
  font-size: 18px;
  font-weight: 700;
}

.vet-name {
  margin: 2px 0;
  min-height: 18px;
  font-size: 12px;
  font-weight: 400;
}

.bone {
  position: relative;
  overflow: hidden;
  background-color: $blue;
  border-radius: 4px;

  &.round {
    border-radius: 50%;
  }

  &::after {
    content: '';
    animation: loading 1.5s infinite;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 100%;
    background: linear-gradient(90deg, rgba($white, 0), rgba($white, 0.3), rgba($white, 0));
    transform: translateX(-100%);
    z-index: 1;
  }
}

@keyframes loading {
  100% {
    transform: translateX(100%);
  }
}
