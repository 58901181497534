* {
  box-sizing: border-box;
}
html {
  height: 100%;
}

body {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  font-family: $font;
  font-weight: 400;
  margin: 0;
  padding: 0;
  background: url('/img/background-paw.svg') no-repeat left -72px bottom -18px;

  @include for-tablet {
    background: url('/img/background-tablet.png') no-repeat;
    background-attachment: fixed;
    background-size: cover;
  }
}

#root {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: 18px;

  @include screen-mobile {
    margin-top: 16px;
  }
}

.container {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  --bs-gutter-x: 15px;
  padding: 0 16px 75px;

  @include for-tablet {
    max-width: 1232px;
  }
}
h1 {
  font-family: $font-secondary;
  font-size: 36px;
  font-weight: 700;
  color: $blue;
}

ul,
ol {
  list-style: none;
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
}

h2 {
  font-family: $font-secondary;
  font-size: 24px;
  line-height: 36px;
  font-weight: 700;
  color: $grey-active;
}

h3 {
  font-family: $font;
  font-size: 18px;
  line-height: 30px;
  font-weight: 700;
  color: $grey-active;

  @include for-tablet {
    font-size: 24px;
    line-height: 36px;
  }
}

p {
  margin: 0;
  font-family: $font;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: $grey-hover;

  @include for-tablet {
    font-size: 18px;
    line-height: 30px;
  }
}
