.diagnosis {
  margin: 16px auto 160px;

  @include for-tablet {
    margin: -46px auto 206px;
    max-width: 791px;
  }

  & > * {
    margin-bottom: 32px;
  }

  .diagnosis-content {
    position: relative;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: -7px;
      left: 0;
      width: 32px;
      height: 32px;
    }

    &--info::before {
      background: url('/img/icon-info.svg') no-repeat;
    }

    &--recommendations::before {
      background: url('/img/icon-med-card.svg') no-repeat;
    }

    @include for-tablet {
      &::before {
        width: 40px;
        height: 40px;
      }

      &--info::before {
        background: url('/img/icon-info-lg.svg') no-repeat;
      }

      &--recommendations::before {
        background: url('/img/icon-med-card-lg.svg') no-repeat;
      }
    }
  }

  ul li {
    position: relative;
    margin-bottom: 16px;
    margin-left: 50px;

    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    color: #94a2b3;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 4px;
      left: -16px;
      width: 10px;
      height: 10px;
      background: url('/img/icon-paw.svg') no-repeat;
      opacity: 0.5;
    }

    @include for-tablet {
      margin-left: 100px;

      &::before {
        left: -40px;
        width: 20px;
        height: 20px;
        background: url('/img/icon-paw-lg.svg') no-repeat;
      }
    }
  }

  a {
    color: $blue;
  }
}

.diagnosis-actions {
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 0;
  right: 0;
  max-width: 383px;
  width: calc(100% - 32px);
  bottom: 24px;
  margin: 0 auto;

  .base-btn:not(:last-child) {
    margin-bottom: 16px;
  }

  @include for-tablet {
    bottom: 40px;
    margin: 0 auto;

    .base-btn {
      padding: 20px;
    }
  }
}

.diagnosis-vet-widget {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 630px;
  margin: 0 auto 35px;
  padding: 20px 22px 30px;
  background-color: $light-baby-blue;
  border-radius: 10px;

  @include screen-mobile {
    width: 100%;
    padding: 30px 0;

    &::before,
    &::after {
      display: none;
    }
  }

  &::before {
    content: '';
    position: absolute;
    top: 16px;
    right: -16px;
    width: 31px;
    height: 25px;
    background-image: url('/img/icon-decor-3.svg');
  }

  &::after {
    content: '';
    position: absolute;
    top: 138px;
    left: -34px;
    width: 76px;
    height: 17px;
    background-image: url('/img/icon-decor-2.svg');
  }

  .vet-widget-title {
    margin: 0 auto 20px;
    color: $blue;
    font-weight: 700;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
  }

  .vet-widget-content {
    display: flex;
    margin: 0 -12px;

    @include screen-mobile {
      flex-direction: column;
    }
  }

  .vet-widget-time {
    margin: 0 12px;

    // to do: replace at design 2022 buttons
    .base-btn {
      position: relative;
      justify-content: center;
      align-items: center;
      width: 328px;
      height: 50px;
      margin-top: 20px;
      padding: 0;
      font-weight: 500;
      font-size: 20px;
      line-height: 20px;
      background-color: $green-active;

      @include screen-mobile {
        width: 300px;
        margin: 20px auto;
      }

      img {
        position: absolute;
        right: 32px;
      }
    }
  }

  .vet-widget-list {
    position: relative;
    margin: 0 12px;

    &::after {
      content: '';
      position: absolute;
      bottom: 10px;
      right: -34px;
      width: 68px;
      height: 56px;
      background-image: url('/img/icon-decor-1.svg');

      @include screen-mobile {
        display: none;
      }
    }

    li {
      margin-bottom: 10px;
      margin-left: 36px;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;

      &::before {
        opacity: 1;
      }

      @include screen-mobile {
        margin-left: 56px;

        &::before {
          left: -36px;
          width: 20px;
          height: 20px;
          background-size: contain;
        }
      }
    }
  }
}
