// shadows
$card-shadow: 0px 18px 52px rgba(#d7e4f9, 0.5);
$card-shadow-hover: 0px 18px 52px $light-babyblue;
$shadow-size: 0px 16px 35px;
$shadow-size-secondary: 0px 2px 14px;

// screen sizes
$mobile: 767px;
$tablet: 1024px;
$laptop: 1366px;
