.base-progress-bar {
  position: relative;
  background: transparent;
  overflow: hidden;
  width: 100%;
  height: 4px;
  transition: 0.2s cubic-bezier(0.4, 0, 0.6, 1);

  &--rounded,
  &--rounded .base-progress-bar-progress {
    border-radius: 5px;
  }

  &-background,
  &-progress {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    transition: inherit;
  }

  &-background {
    right: 0;
    background-color: $block;
  }

  &-progress {
    background-color: $blue;
  }

  &--indeterminate {
    .base-progress-bar-progress {
      animation: indeterminateAnimation 1s infinite linear;
      transform-origin: 0% 50%;
    }
  }
}

@keyframes indeterminateAnimation {
  0% {
    transform: translateX(0) scaleX(0);
  }
  40% {
    transform: translateX(0) scaleX(0.4);
  }
  100% {
    transform: translateX(100%) scaleX(0.5);
  }
}
