.actions-bar {
  position: absolute;
  top: 30px;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 16px;

  @include screen-tablet {
    position: fixed;
    top: auto;
    bottom: 0;
    margin: 0;
    padding: 20px 40px;
    background-color: $light-baby-blue;
    z-index: 1;
  }

  .actions-bar-btn {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    border: 0;
    background-color: transparent;
    color: $blue;
    cursor: pointer;

    &:hover {
      color: $blue-hover;
    }
  }
}
