.base-modal {
  .modal-dialog {
    max-width: 587px;
  }

  .modal-content {
    margin: 8px;
    padding: 32px 16px;
    background: $white;
    border: 0;
    border-radius: 20px;
    box-shadow: 0px 18px 52px rgba(71, 71, 71, 0.8);

    @include for-tablet {
      padding: 32px 27px;
    }
  }

  .modal-body {
    padding: 0;
  }

  .modal-image {
    position: relative;
    display: flex;
    margin: 0 auto 30px;
    width: 100px;
    height: 100px;
    background-color: $baby-blue;
    border-radius: 50%;

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-height: 100px;
    }
  }

  .modal-title {
    margin: 0 16px 12px;
    text-align: center;
  }

  .modal-description {
    margin: 0 16px 24px;
    text-align: center;
  }

  .modal-buttons {
    display: flex;
    flex: 1;
    margin: 0 -6px;

    .base-btn {
      flex: 1 1 auto;
      margin: 0 6px;
    }
  }
}


.modal-insurance {
  .modal-logos {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
  }

  .modal-logos img {
    max-width: 150px;
    width: 100%;
    padding: 0 10px;

    &:first-child {
      border-right: 1px solid $block;
    }

    @include for-mobile {
      max-width: 200px;
      padding: 0 20px;
    }

    @include for-tablet {
      max-width: 230px;
    }
  }

  .base-spinner {
    display: flex;
    margin: 0 auto 30px;
  }
}
