.base-btn {
  &.btn {
    padding: 16px;
    font-size: 20px;
    border: 0;
    border-radius: 10px;
    transition: all 0.2s;

    &:focus {
      box-shadow: none;
    }

    &:disabled,
    &.disabled {
      background: $block;
      box-shadow: 0px 10px 20px rgba($grey-hover, 0.2);
      opacity: 1;
    }
  }

  &.btn-rounded {
    font-size: 14px;
    font-weight: 500;
    padding: 5px 24px;
    line-height: 30px;
    border-radius: 25px;
  }

  &.btn-primary {
    background-color: $blue;
    box-shadow: 0px 8px 35px rgba($blue, 0.5);

    &:hover {
      color: rgba($white, 0.7);
      background-color: $blue-hover;
      box-shadow: 0px 16px 50px rgba($blue-hover, 0.7);
    }

    &:active,
    &:focus {
      background-color: $blue-active;
      box-shadow: 0px 16px 50px rgba($blue-active, 0.5);
    }
  }

  &.btn-success {
    background-color: $green;
    box-shadow: 0px 8px 35px rgba($green, 0.5);

    &:hover {
      color: rgba($white, 0.7);
      background-color: $green-hover;
      box-shadow: 0px 16px 50px rgba($green-hover, 0.7);
    }

    &:active,
    &:focus {
      background-color: $green-active;
      box-shadow: 0px 16px 50px rgba($green-active, 0.5);
    }
  }

  &.btn-danger {
    background-color: $pink;
    box-shadow: 0px 8px 35px rgba($pink, 0.5);

    &:hover {
      color: rgba($white, 0.7);
      background-color: $pink-hover;
      box-shadow: 0px 16px 50px rgba($pink-hover, 0.7);
    }

    &:active,
    &:focus {
      background-color: $pink-active;
      box-shadow: 0px 16px 50px rgba($pink-active, 0.5);
    }
  }

  &.btn-secondary {
    background-color: $block;
    box-shadow: 0px 8px 35px rgba($block, 0.5);

    &:hover {
      color: rgba($white, 0.7);
      background-color: $grey-clock;
      box-shadow: 0px 16px 50px rgba($grey-clock, 0.7);
    }

    &:active,
    &:focus {
      background-color: $grey;
      box-shadow: 0px 16px 50px rgba($grey, 0.5);
    }
  }

  &.btn-warning {
    color: $white;
    background-color: $yellow;
    box-shadow: 0px 8px 35px rgba($yellow, 0.5);

    &:hover {
      color: $white;
      color: rgba($white, 0.7);
      background-color: $yellow-hover;
      box-shadow: 0px 16px 50px rgba($yellow-hover, 0.7);
    }

    &:active,
    &:focus {
      color: $white;
      background-color: $yellow-active;
      box-shadow: 0px 16px 50px rgba($yellow-active, 0.5);
    }
  }

  &.btn-outline-secondary {
    padding: 8px 24px;
    color: $grey;
    background-color: transparent;
    border: 2px solid $grey;

    &:hover {
      color: $grey-hover;
      background-color: inherit;
      border: 2px solid $grey-hover;
    }

    &:active,
    &:focus {
      color: $grey-active;
      background-color: inherit;
      border: 2px solid $grey-active;
    }
  }

  &.btn-icon {
    display: flex;
    padding: 11px;

    svg {
      margin-left: 4px;
      flex-shrink: 0;
    }

    span {
      width: 100%;
    }
  }

  &.btn-back {
    width: 46px;
    height: 46px;
    padding: 0;
    border-radius: 15px;
    transform: rotate(135deg);

    svg {
      transform: rotate(-135deg);
    }

    &:focus {
      box-shadow: none;
    }
  }

  &.flat {
    box-shadow: none;

    &:hover,
    &:focus,
    &:active {
      box-shadow: none;
    }
  }
}
